@use '@angular/material' as mat;
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap');

@include mat.core();

$promptbet-webview-primary: mat.define-palette(mat.$indigo-palette);
$promptbet-webview-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$promptbet-webview-warn: mat.define-palette(mat.$red-palette);

$promptbet-webview-theme: mat.define-light-theme((
  color: (
    primary: $promptbet-webview-primary,
    accent: $promptbet-webview-accent,
    warn: $promptbet-webview-warn,
  )
));

@include mat.all-component-themes($promptbet-webview-theme);

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

* {
  user-select: none;
  -webkit-touch-callout:none;
  -webkit-user-select:none;
  -khtml-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  -webkit-tap-highlight-color:rgba(0,0,0,0);
}

::ng-deep * {
  user-select: none;
  -webkit-touch-callout:none;
  -webkit-user-select:none;
  -khtml-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  -webkit-tap-highlight-color:rgba(0,0,0,0);
}
